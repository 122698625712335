import React from 'react'
import everyone from '../../assets1/herooo.png'
import styles from './Hero.module.css'

const Hero = () => {
  return (
        <div className="container">
            <div className={styles.additions}></div>
            <div className="row ms-auto align-items-center">
                <div className={`col-lg-8 ${styles.heroText}`}>
                    Reimagining Decent Jobs for Young Africans Across the
                    Globe.
                </div>
                <div className={`col-lg-4 mt-5 ${styles.heroImage}`}>
                    <img src={everyone} alt="everyone" className="img-fluid" />
                </div>
            </div>
            <div className={styles.additions}></div>
        </div>
  )
}

export default Hero