import React from "react";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoArrowUpOutline,
} from "react-icons/io5";
import { FaLinkedinIn } from "react-icons/fa";
import naomi_lucas_logo from "../../assets1/logos/nlco-2.svg";
import styles from "./Footer.module.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.footer}>
      <div className="container">
        <div className="row text-center">
          <div className="col-md-4 pt-4">
            <img src={naomi_lucas_logo} alt="naomi lucas logo" />
          </div>
          <div className="col-md-4">
            <div className="container">
              <div className="row ms-auto pt-4">
                <div className="col-md-12">
                  <span className={styles.usefulLink}>useful links</span>
                </div>
                <div className="col-md-12 pt-4">
                  <span className={styles.terms}>
                    <a
                      href="https://naomilucas.xyz/wp/terms-and-conditions/"
                      rel="noopener noreferrer"
                    >
                      terms of use
                    </a>
                  </span>
                </div>
                <div className="col-md-12">
                  <span className={styles.terms}>
                    <a
                      href="https://naomilucas.xyz/wp/privacy-policy/"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>
                  </span>
                </div>
                <div className={`col-md-12  pt-4 ${styles.courtesy}`}>
                  &copy; {currentYear} Naomi Lucas
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 pt-4">
            <a
              class={styles.footer_link}
              target="_blank"
              href="https://web.facebook.com/NaomiLucasCo/"
              rel="noopener noreferrer"
              style={{ marginRight: "8px" }}
            >
              <span>
                <IoLogoFacebook />
              </span>
            </a>
            <a
              class={styles.footer_link}
              target="_blank"
              href="https://www.instagram.com/naomilucasco/"
              rel="noopener noreferrer"
              style={{ marginRight: "8px" }}
            >
              <span>
                <IoLogoInstagram />
              </span>
            </a>
            <a
              class={styles.footer_link}
              target="_blank"
              href="https://www.linkedin.com/company/naomi-lucas/"
              rel="noopener noreferrer"
            >
              <span>
                <FaLinkedinIn />
              </span>
            </a>
          </div>
          <div class={`col-md-12 text-center p-0 mt-5 ${styles.backToTop}`}>
            <a href="#home">
              <span>
                <IoArrowUpOutline />
              </span>
            </a>
          </div>
          <div class={`col-md-12 text-center pt-2 ${styles.backToTop_text}`}>
            <span>Back to top</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
