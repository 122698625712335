import React from "react";
import styles from "./Manifesto.module.css";

const Manifesto = () => {
  return (
    <div id="manifesto" className={`${styles.manifesto} py-5`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <span className={styles.manifestoHeader}>OUR MANIFESTO</span>
          </div>
          <div className="col-11 col-sm-6 text-center pt-5">
            <span className={`${styles.manifesto_text}`}>
              We are Naomi Lucas,
              <br /> We believe in the African Youth,
              <br /> We believe in their ability to find her purpose,
              <br /> We believe they can transform their affairs and that of
              their environment,
              <br /> We see their pain, challenges, and struggles,
              <br /> We reject stereotypes that limit their potential,
              <br /> We are amazed at their ability to thrive even in the most
              adverse conditions.
              <br />
              <br />
              We believe in their dreams and aspirations.
              <br /> And as a collective, we remain committed to finding new
              ways to reach and connect them to life-changing opportunities
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manifesto;
