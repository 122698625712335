import './App.css';
import Brands from './Components/Brands/Brands';
import Manifesto from './Components/Manifesto/Manifesto';
import RootComponent from './Components/RootComponent';
import Partner from './Components/PartnerReg/Partner'
import { Route, Routes } from 'react-router';
import Contacts from './Components/ContactReg/ConctactReg';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<RootComponent />} />
        <Route path='/partner' element={<Partner />} />
        <Route path='/manifesto' element={<Manifesto />} />
        <Route path='/brands' element={<Brands />} />
        <Route path='/contact' element={<Contacts />} />
      </Routes>
    </>
  );
}

export default App;
