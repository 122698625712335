import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import styles from "./Brands.module.css";
import graduatePro from "../../assets1/logos/GradPro Logo (Black n Orange).png";
import iagnw from "../../assets1/logos/IAGNW Logo (Black).png";
import teb from "../../assets1/logos/TEB Logo (Black).png";
import handshake from "../../assets1/logos/Handshake Logo (Blue).png";

const Brands = () => {
  const [selectedBrand, setSelectedBrand] = useState(null);

  const handleBrandClick = (brand) => {
    setSelectedBrand(brand);
  };

  const handleBrandClose = () => {
    setSelectedBrand(null);
  };

  const brandList = [
    {
      id: 1,
      image: graduatePro,
      title: "Graduate Pro",
      about:
        "Graduatepro Early Career Accelerator is an Intensive coordinated and sustained approach to bridging the gap between the demand for work-ready talent and the supply of decent, meaningful jobs.",
      hovertag: "learnMore",
    },
    {
      id: 2,
      image: iagnw,
      title: "I am A Graduate Now What",
      about:
        "I’m A Graduate Now What? Audio Experience is a vibrant blend of original music, narration, drama and storytelling narrated by 56 notable Nigerians. With over five hundred thousand copies sold, I’m A Graduate Now What? Audio Experience has become the definitive guide for those who want to win in the global talent marketplace.",
      hovertag: "learnMore1",
    },
    {
      id: 3,
      image: handshake,
      title: "Handshake",
      about:
        "Handshake! Real World Perspectives is a carefully curated line up of virtual events that brings together seasoned professionalsand business leaders to share real world perspectives with young African professionals across the globe.",
      hovertag: "learnMore2",
    },
    {
      id: 4,
      image: teb,
      title: "The Employment Bootcamp",
      about:
        "An intensive and outcome-focused initiative, The Employment Bootcamp is a systemic approach to solving Africa’s youth unemployment problem. Leveraging multimedia content, gamification and a learn-by-doing approach, participants are given the tools, resources and network they need to create their own opportunities.",
      hovertag: "learnMore3",
    },
  ];

  return (
    <div id="brands" className={`${styles.brands} pt-5`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center" id={styles.brandsHeader}>
                  <span className={styles.brandsHeader}>OUR BRANDS</span>
                </div>
                <div className="col-12 text-center pt-4" id={styles.brandsText}>
                  <span className={styles.brandsText}>
                    We are a venture studio creating solutions that increase
                    access to economic opportunities for young Africans across
                    the globe.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 py-5 my-4">
              <div className="container">
                <div className="row text-center">
                  {brandList.map((b) => (
                    <div
                      className="col-lg-3"
                      key={b.id}
                      onClick={(e) => handleBrandClick(b)}
                    >
                      <div className={styles.brandUI}>
                        <img
                          src={b.image}
                          alt="naomi lucas brands"
                          className="img-fluid"
                        />
                        <div className={`container ${styles[b.hovertag]}`}>
                          <div className="row">
                            <div className="col-12">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_3380_1344)">
                                  <path
                                    d="M11.3332 4.66602L4.6665 11.3327"
                                    stroke="white"
                                    stroke-width="1.6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M5.3335 4.66602H11.3335V10.666"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_3380_1344">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div
                              className={`col-12 animate__animated animate__slideInUp ${styles.learnBtn}`}
                            >
                              learn more
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {selectedBrand && (
              <Modal
                show={selectedBrand != null}
                onHide={handleBrandClose}
                backdrop={true}
                keyboard={false}
                size="lg"
                centered
                className="rounded-0"
              >
                <div
                  onClick={handleBrandClose}
                  className="col-12 text-end py-2 px-3"
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_3881_838)">
                      <path
                        d="M18 6L6 18"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 6L18 18"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3881_838">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={12} md={12} className="px-4">
                        <h6 className={styles.modalBdy}>
                          {selectedBrand.about}
                        </h6>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
