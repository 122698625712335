import React, { useState } from 'react'
import PageNav from './Navbar/Navbar'
import Hero from './Hero/Hero'
import Manifesto from './Manifesto/Manifesto'
import Featured from './Featured/Featured'
import Brands from './Brands/Brands'
import OurService from './OurServices/OurService'
import Footer from './Footer/Footer'
import WhatPeopleSay from './WhatPeopleSay/WhatPeopleSay'
import ellipse13 from '../assets1/logos/Ellipse 13.svg'
import vector9 from '../assets1/logos/Vector 9.svg'
import styles from './RootComponent.module.css'


const RootComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`${styles.majorHero}`}>
        <div className={styles.headerShapedback}></div>
        <div className={styles.headerMobShapedback}>
          <svg xmlns="http://www.w3.org/2000/svg" width="211" height="465" viewBox="0 0 211 465" fill="none">
            <path d="M197.615 445.51C-141.613 344.815 31.6023 65.4364 160.613 -61.6662L323.049 -107.571L576.508 -142L642 62.7216C635.216 232.274 536.842 546.205 197.615 445.51Z" fill="#31CED2" fill-opacity="0.25"/>
          </svg>
        </div>
        <PageNav className={styles.nav} handleToggleClick={handleToggleClick} isMenuOpen={isMenuOpen} />
        <div className={`${isMenuOpen ? styles.blur : ''}`}>
          <Hero className={styles.hero} />
          <Manifesto />
          <div className={styles.manifestoBack}>
              <img src={ellipse13} alt="backgorund shape" />
          </div>

          <div className={styles.brandsBack}>
          </div>

          <div className={styles.brandsback1}>
            <img src={vector9} alt="background shape" />
          </div>
          <Brands />
          <OurService />
          <Featured />
          <WhatPeopleSay />
          <Footer />
        </div>
    </div>
  )
}

export default RootComponent