import React, { useState } from 'react'
import pelu from '../../assets1/quotes/Pelu_Awofeso (1).png'
import ese from '../../assets1/quotes/Ese_Oraka (1).png'
import lanre from '../../assets1/quotes/Lanre_Olusola (1).png'
import ukeje from '../../assets1/quotes/UC_Ukeje (1).png'
import emmanuel from '../../assets1/quotes/emmanuel.webp'
import comfort from '../../assets1/quotes/comfort.webp'
import anita from '../../assets1/quotes/anita.webp'
import john from '../../assets1/quotes/john.webp'
import kola from '../../assets1/quotes/Kola_Oyeneyin (1).png'
import sufiyan from '../../assets1/quotes/sufyan (1).png'
import yakub from '../../assets1/quotes/yakub.webp'
import igoje from '../../assets1/quotes/igoje.webp'
import grace from '../../assets1/quotes/grace.webp'
import joseph from '../../assets1/quotes/joseph (1).png'
import styles from './WhatPeopleSay.module.css'
import { HiMiniArrowRight } from "react-icons/hi2";
import { HiMiniArrowLeft } from "react-icons/hi2";

const WhatPeopleSay = () => {
    const peoplesay = [
        {
            name: "Pelu Awofeso",
            saying: "I’m A Graduate Now What? Is going to shape the continent!",
            profession: "Award-winning Travel Writer",
            image: pelu,
            id: "pelu"
        },
        {
            name: "Ese Oraka",
            saying: "I’m A Graduate Now What? Is original. Nothing like it has ever been done before. It has great potential for impact.",
            profession: "Partner, Adelphi Innovation Consulting",
            image: ese,
            id: "ese"
        },
        {
            name: "Lanre Olusola",
            saying: "This is one of the most laudable initiatives I have ever been involved with.",
            profession: "Life Coach",
            image: lanre,
            id: "lanre"
        },
        {
            name: "OC Ukeje",
            saying: "A think-outside-the-box strategy to engaging young people. It’s a brilliant idea",
            profession: "Actor",
            image: ukeje,
            id: "oc"
        },
        {
            name: "Kola Oyeneyin",
            saying: "This project is incredibly innovative. It is revolutionary. I believe it has the potential of injecting a whole new kind of energy in Nigeria and Indeed Africa.",
            profession: "CEO, Opportunik Global Fund",
            image: kola,
            id: "kola"
        },
        {
            name: "Emmanuel Damilola Arowolo",
            saying: "This program aligns perfectly with my ongoing journey of self-improvement and development. I'm incredibly thankful for this opportunity and genuinely excited about completing the other seven levels of the program, as each step promises to bring fresh insights and tools to help me flourish in my career.",
            profession: " ",
            image: emmanuel,
            id: "emma"
        },
        {
            name: "Anita Imogu",
            saying: "I'm genuinely amazed at the insights I've gained so far. Can't wait to see how this knowledge will transform my career and life. Kudos to The Employment Bootcamp team for curating such impactful content!",
            profession: " ",
            image: anita,
            id: "anita"
        },
        {
            name: "Yakub AbdulMalik",
            saying: "The employment bootcamp live sessions have been very exciting. They are basically cheat code sessions. These insights are powerful tools to keep in your arsenal as you tackle the ups and downs of job search. My expectations are always exceeded and leave with more than I thought I would gain. Every session I feel like Ihave learnt so much, only until the next session where I am blown away again.",
            profession: " ",
            image: yakub,
            id: "yakub"
        },
        {
            name: "Sufyan Ibrahim",
            saying: "As I reflect on my experience, I'm amazed by how much I've grown. This phase of the bootcamp has not only equipped me with tangible skills but has also boosted my confidence. I am eager to implement these strategies as I take the next steps in my professional journey.",
            profession: " ",
            image: sufiyan,
            id: "sufiyan"
        },
        {
            name: "John Nwachukwu Kanu",
            saying: "Level 4 talked about writing rewarding CVs and Cover Letters. This particular level made me see the major reason why I haven't been selected for any role, despite my many applications. We got to understand the major things employers or recruiters look out for in the recruiting process.",
            profession: " ",
            image: john,
            id: "john"
        },
        {
            name: "Igoje Michael",
            saying: "This journey of self-discovery has shaped my life's perspective, my thought process and my worldview with everything consciously centering around my values.",
            profession: " ",
            image: igoje,
            id: "igoje"
        },
        {
            name: "Grace Chinadindu Kalu",
            saying: "One of the reasons why I haven't posted is because I didn't know how to put myself out there as a Creative. Today, I'm here to show up and I won't do this, without acknowledging the force behind the new me. The Employment Bootcamp has been a blessing!",
            profession: " ",
            image: grace,
            id: "grace"
        },
        {
            name: "Comfort Johnson",
            saying: "This is not a typical online bootcamp – it's a personalized and transformational experience unlike another I've attended. This is a safe space to let your   guards down, get help, and get it right.",
            profession: " ",
            image: comfort,
            id: "comfort"
        },
        {
            name: "Joseph Awara",
            saying: "Unlike the millions of boring learning platforms around, this particular learning game is something totally different and special. It was designed with the intention of bringing fun to you while you progress through the different learning levels and challenges in the game. It will really push you to discard what doesn't work, and then relearn new real world skills to stand out and be the champion of your life.",
            profession: " ",
            image: joseph,
            id: "joseph"
        },
    ]

    const [currentIndex, setCurrentIndex] = useState(0);
    const totalCards = peoplesay.length;

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
    };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
    };

  return (
    <div className={styles.people}>
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <span className={styles.peopleHeader}>
                        what people are saying
                    </span>
                </div>
                <div className="col-12 p-5">
                    <div className={styles.sliderCardMain}>
                        {peoplesay.map((p, index) => (
                            <div key={index} className={`${styles.sliderCard} ${index === currentIndex ? "" : "d-none"}`}>
                                <div className={`${styles.sliderImage} ${styles[p.id]}`}>
                                    <img src={p.image} alt="images of people" width={145} height={145} />
                                </div>
                                <div className={`row ${styles.sSlider}`}>
                                    <div className={`col-md-12 ${styles.sliderName}`}>
                                        <span>{p.name}</span>
                                    </div>
                                    <div className={`col-12 ${styles.sliderProfession}`}>
                                        <span>{p.profession}</span>
                                    </div>
                                    <div className={`col-12 ${styles.sliderDescription} pt-3`}>
                                        <span>{p.saying}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className={`${styles.sliderBtn} ${styles.prev}`} onClick={handlePrevClick}>
                          <HiMiniArrowLeft />
                        </div>
                        <div className={`${styles.sliderBtn} ${styles.next}`} onClick={handleNextClick}>
                          <HiMiniArrowRight />
                        </div>
                    </div>
                    <div className={`${styles.sliderIndicator} p-0`}>
                        {[...Array(totalCards)].map((_, index) => (
                            <span key={index} className={`${styles.dot} ${index === currentIndex ? styles.active : ""}`} style={{marginLeft: '0.3rem'}}></span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhatPeopleSay