import React from 'react'
import styles from './Featured.module.css'
import featuredIn from '../../assets1/featuredInTeal-removebg-preview.png'

const Featured = () => {
  return (
    <div className={styles.featured}>
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <span className={styles.featuredHeader}>
                        featured in
                    </span>
                </div>
                <div className={`col-md-12 p-3 ${styles.featuredinImages}`}>
                    <img loading='lazy' src={featuredIn} alt="featured in" />
                    <img loading='lazy' src={featuredIn} alt="featured in" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Featured