import React, { useEffect, useState } from "react";
import naomi_logo from "../../assets1/logos/nlco1.svg";
import naomi_logo_white from "../../assets1/logos/nlco-2.svg";
import { Container, Nav, Navbar } from "react-bootstrap";
import { IoLogoFacebook, IoLogoInstagram, IoClose } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaLinkedinIn } from "react-icons/fa";
import { LuArrowUpRight } from "react-icons/lu";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";

const PageNav = ({ handleToggleClick, isMenuOpen }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    handleToggleClick();
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`text-center sticky-top ${styles.navCon} ${
        scrolled ? styles.scrolled : ""
      } ${isMenuOpen ? styles.open : ""}`}
    >
      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          as="button"
          className={`navbar-toggler shadow-none bx-menu-button ${
            scrolled ? "text-white" : ""
          }`}
          onClick={handleClick}
          style={{ border: "none" }}
        >
          {isMenuOpen ? <IoClose /> : <GiHamburgerMenu />}
        </Navbar.Toggle>
        <Navbar.Brand>
          <Link to={"/"}>
            <img
              src={scrolled ? naomi_logo_white : naomi_logo}
              alt="naomi lucas logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={`justify-content-md-end ${styles.navColl}  ${
            isMenuOpen ? styles.open1 : ""
          }`}
        >
          <Nav>
            <Nav.Link>
              <Link
                to={"/"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLink} ${
                  scrolled ? styles.navLinkScrolled : ""
                }`}
                onClick={() => scrollToSection("manifesto")}
              >
                <LuArrowUpRight className={styles.arrowUp} />
                Manifesto
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to={"/"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLink} ${
                  scrolled ? styles.navLinkScrolled : ""
                }`}
                onClick={() => scrollToSection("brands")}
              >
                <LuArrowUpRight className={styles.arrowUp} />
                Brands
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to={"/partner"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLink} ${
                  scrolled ? styles.navLinkScrolled : ""
                }`}
              >
                <LuArrowUpRight className={styles.arrowUp} />
                Partner
              </Link>
            </Nav.Link>
            <Nav.Link className={`d-none d-lg-block`}>
              <Link
                to={"/contact"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLinkBtn} ${
                  scrolled ? styles.navLinkBtnScrolled : ""
                }`}
              >
                <LuArrowUpRight className={styles.arrowUp} />
                Contact
              </Link>
            </Nav.Link>
            <Nav.Link
              target="_blank"
              href="https://web.facebook.com/NaomiLucasCo/"
              className={`d-none d-lg-block ${styles.navBtn} ${
                scrolled ? styles.navBtnScrolled : ""
              }`}
              style={{ marginLeft: "1rem" }}
            >
              <IoLogoFacebook />
            </Nav.Link>
            <Nav.Link
              target="_blank"
              href="https://www.instagram.com/naomilucasco/"
              className={`d-none d-lg-block ${styles.navBtn} ${
                scrolled ? styles.navBtnScrolled : ""
              }`}
            >
              <IoLogoInstagram />
            </Nav.Link>
            <Nav.Link
              target="_blank"
              href="https://www.linkedin.com/company/naomi-lucas/"
              className={`d-none d-lg-block ${styles.navBtn} ${
                scrolled ? styles.navBtnScrolled : ""
              }`}
            >
              <FaLinkedinIn />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default PageNav;
