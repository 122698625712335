import React from 'react';
import styles from './OurService.module.css';
import advisor from '../../assets1/adviserResize-removebg-preview.png'
import designer from '../../assets1/designThinking1-removebg-preview.png'
import curator from '../../assets1/curatorResize-removebg-preview.png'

const OurService = () => {
    //service list
    const services = [
        {
            header: "WE ADVICE CORPORATES AND GOVERNMENTS",
            body: "We understand the business of youth. We work with the public and private sector to design, develop and implement youth-focused initiatives. By leveraging our experience, you can dramatically improve the outcomes of your programs, campaigns or interventions.",
            image: advisor,
            alt: "government advisor",
            hoverTag: "card1"
        },
        {
            header: "WE CURATE FAIRS AND MASTERCLASSES",
            body: "We curate carefully tailored career fairs, masterclasses and events to help you find, attract and maintain a healthy pipeline of world-class talent thereby reducing time to fill and improving the quality of candidates.",
            image: curator,
            alt: "masterclass curator",
            hoverTag: "card2"
        },
        {
            header: "WE DESIGN LEARNING EXPERIENCES",
            body: "We are bullish about bridging the gap between the demand for work-ready talent and the supply of decent, meaningful work. We design experiences that equip young people with the skills and tools they need to thrive in a global marketplace.",
            image: designer,
            alt: "learning experiences designer",
            hoverTag: "card3"
        }
    ];

    return (
        <div className={styles.services}>
            <div className="container gap-3">
                <div className="row">
                    <div className="col-12 text-center">
                        <span className={styles.serviceHeader}>OUR SERVICES</span>
                    </div>
                    <div className="col-12 pt-3">
                        <div className="row justify-content-evenly">
                            {services.map((service, index) => (
                                <div class={`card ${styles.cardBack} ${styles[service.hoverTag]}`} style={{width: '22rem', marginTop: "2rem"}}>
                                    <img src={service.image} class="card-img-top" alt={service.alt} />
                                    <div class={`card-body text-center ${styles.serviceTitle}`}>
                                      <h5 class="card-title">{service.header}</h5>
                                      <p class="card-text pt-3">{service.body}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurService;
